import React, { useContext, useState } from 'react';
import { DataContext } from "../DataContexts/DataContext";
import MachineManager from './MachineManager';
import MachineDetails from './MachineDetails';
import './MachineHeader.css';

const MachineHeader = () => {
  const { loading, error } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState('machineManager');

  return (
    <div className="machine-header-container">
      <div className="tab-buttons">
        <button
          className={`tab ${activeTab === 'machineManager' ? 'active' : ''}`}
          onClick={() => setActiveTab('machineManager')}
        >
          List view
        </button>
        <button
          className={`tab ${activeTab === 'machineDetails' ? 'active' : ''}`}
          onClick={() => setActiveTab('machineDetails')}
        >
          Detail view
        </button>
      </div>
      <div className="tab-content-machine">
        {activeTab === 'machineManager' && <MachineManager />}
        {activeTab === 'machineDetails' && <MachineDetails />}
      </div>
    </div>
  );
};

export default MachineHeader;
