import React, { useContext, useState, useMemo } from 'react';
import { MachineContext } from '../DataContexts/MachineContext';
import './MachineManager.css'; // Import CSS file

const MachineManager = () => {
  const { machines, loading, error } = useContext(MachineContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Add statistics calculations using useMemo
  const statistics = useMemo(() => {
    const totalMachines = machines.length;
    const onlineMachines = machines.filter(machine => {
      if (!machine.lastSeen || machine.lastSeen.length < 6) return false;
      const lastSeenDate = new Date(
        machine.lastSeen[0], machine.lastSeen[1] - 1, machine.lastSeen[2], 
        machine.lastSeen[3], machine.lastSeen[4], machine.lastSeen[5]
      );
      const now = new Date();
      const diffMinutes = (now - lastSeenDate) / (1000 * 60);
      return diffMinutes <= 60;
    }).length;

    // Calculate total income
    const totalIncome = machines.reduce((sum, machine) => {
      const income = (machine.currentCoinDeposit || 0) + (machine.currentIncomeFromPayconiq || 0);
      return sum + income;
    }, 0);

    // Group machines by province
    const provinceStats = machines.reduce((acc, machine) => {
      const province = machine.province || 'Unknown';
      if (!acc[province]) {
        acc[province] = { total: 0, online: 0, income: 0 };
      }
      acc[province].total += 1;

      // Check if machine is online
      if (machine.lastSeen && machine.lastSeen.length >= 6) {
        const lastSeenDate = new Date(
          machine.lastSeen[0], machine.lastSeen[1] - 1, machine.lastSeen[2],
          machine.lastSeen[3], machine.lastSeen[4], machine.lastSeen[5]
        );
        const now = new Date();
        const diffMinutes = (now - lastSeenDate) / (1000 * 60);
        if (diffMinutes <= 60) {
          acc[province].online += 1;
        }
      }

      // Add income
      const income = (machine.currentCoinDeposit || 0) + (machine.currentIncomeFromPayconiq || 0);
      acc[province].income += income;

      return acc;
    }, {});

    // Sort provinces by total machines
    const sortedProvinces = Object.entries(provinceStats)
      .sort(([, a], [, b]) => b.total - a.total);

    return {
      totalMachines,
      onlineMachines,
      totalIncome,
      provinceStats: sortedProvinces
    };
  }, [machines]);

  const calculateDaysOffline = (lastSeen) => {
    if (!lastSeen || lastSeen.length < 3) return 'N/A';
    const lastSeenDate = new Date(lastSeen[0], lastSeen[1] - 1, lastSeen[2]);
    const today = new Date();
    const diffTime = Math.abs(today - lastSeenDate);
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  const getStatusIndicator = (lastSeen) => {
    if (!lastSeen || lastSeen.length < 6) return '⚪';
    const lastSeenDate = new Date(
      lastSeen[0], lastSeen[1] - 1, lastSeen[2], lastSeen[3], lastSeen[4], lastSeen[5]
    );
    const now = new Date();
    const diffMinutes = (now - lastSeenDate) / (1000 * 60);
    return diffMinutes <= 60 ? '🟢' : '🔴';
  };

  const calculateRevenue = (machine) => {
    if (machine.currentCoinDeposit === undefined || machine.currentIncomeFromPayconiq === undefined) return 'N/A';
    return ((machine.currentCoinDeposit + machine.currentIncomeFromPayconiq) / 100).toFixed(2).replace('.', ',') + ' €';
  };

  const calculateDaysSinceClear = (coinsLastEmptied) => {
    if (!coinsLastEmptied || coinsLastEmptied.length < 3) return 'N/A';
    const lastClearDate = new Date(coinsLastEmptied[0], coinsLastEmptied[1] - 1, coinsLastEmptied[2]);
    const today = new Date();
    const diffTime = Math.abs(today - lastClearDate);
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  const sortedMachines = [...machines].sort((a, b) => {
    if (!sortConfig.key) return 0;
    let aValue = sortConfig.key === 'daysOffline' ? calculateDaysOffline(a.lastSeen) :
                 sortConfig.key === 'status' ? getStatusIndicator(a.lastSeen) :
                 sortConfig.key === 'daysSinceClear' ? calculateDaysSinceClear(a.coinsLastEmptied) :
                 sortConfig.key === 'revenue' ? parseFloat(calculateRevenue(a)) :
                 a[sortConfig.key] ?? '';
    let bValue = sortConfig.key === 'daysOffline' ? calculateDaysOffline(b.lastSeen) :
                 sortConfig.key === 'status' ? getStatusIndicator(b.lastSeen) :
                 sortConfig.key === 'daysSinceClear' ? calculateDaysSinceClear(b.coinsLastEmptied) :
                 sortConfig.key === 'revenue' ? parseFloat(calculateRevenue(b)) :
                 b[sortConfig.key] ?? '';

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  });


  const filteredMachines = sortedMachines.filter((machine) =>
    [machine.name, machine.city, machine.province, machine.street]
      .some(field => field.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  // Add this new function to generate CSV data
  const downloadCSV = () => {
    // Define headers (removed 'Status')
    const headers = [
      'Days Offline',
      'ID',
      'Name',
      'Street',
      'Postal Code',
      'City',
      'Province',
      'Type',
      'Income',
      'Income Days',
      'Requests'
    ];

    // Convert filtered machines to CSV rows (removed status indicator)
    const csvData = filteredMachines.map(machine => [
      calculateDaysOffline(machine.lastSeen),
      machine.id.slice(0, 8),
      machine.name,
      `${machine.street} ${machine.housenumber}`,
      machine.postalcode,
      machine.city,
      machine.province,
      machine.type,
      calculateRevenue(machine),
      calculateDaysSinceClear(machine.coinsLastEmptied),
      machine.songsRequestedSince ?? 'N/A'
    ]);

    // Combine headers and data
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'machines.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="machine-manager-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search all machines"
          className="search-box"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button 
          className="download-csv-btn" 
          onClick={downloadCSV}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Download CSV'}
        </button>
      </div>
      {loading && <p className="loading">Loading machines...</p>}
      {error && <p className="error">Error: {error}</p>}
      <div className="table-container">
        <table className="machine-table">
          <thead>
            <tr>
              <th onClick={() => requestSort('status')}>{getSortIndicator('status')}</th>
              <th onClick={() => requestSort('daysOffline')}>Seen{getSortIndicator('daysOffline')}</th>
              <th onClick={() => requestSort('id')}>ID{getSortIndicator('id')}</th>
              <th onClick={() => requestSort('name')}>Name{getSortIndicator('name')}</th>
              <th>Street</th>
              <th onClick={() => requestSort('postalcode')}>Post{getSortIndicator('postalcode')}</th>
              <th onClick={() => requestSort('city')}>Place{getSortIndicator('city')}</th>
              <th onClick={() => requestSort('province')}>Province{getSortIndicator('province')}</th>
              <th onClick={() => requestSort('type')}>Type{getSortIndicator('type')}</th>
              <th onClick={() => requestSort('revenue')}>Income (€){getSortIndicator('revenue')}</th>
              <th onClick={() => requestSort('daysSinceClear')}>Income days{getSortIndicator('daysSinceClear')}</th>
              <th onClick={() => requestSort('songsRequestedSince')}>Requests{getSortIndicator('songsRequestedSince')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredMachines.length > 0 ? (
              filteredMachines.map((machine) => (
                <tr key={machine.id}>
                  <td className="center-data">{getStatusIndicator(machine.lastSeen)}</td>
                  <td className="center-data">{calculateDaysOffline(machine.lastSeen)}</td>
                  <td>{machine.id.slice(0, 8)}</td>
                  <td>{machine.name}</td>
                  <td>{`${machine.street} ${machine.housenumber}`}</td>
                  <td className="center-data">{machine.postalcode}</td>
                  <td>{machine.city}</td>
                  <td>{machine.province}</td>
                  <td>{machine.type}</td>
                  <td className="center-data">{calculateRevenue(machine)}</td>
                  <td className="center-data">{calculateDaysSinceClear(machine.coinsLastEmptied)}</td>
                  <td className="center-data">{machine.songsRequestedSince ?? 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12">No machines found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      <div className="stats-container">
        <div className="stat-block">
          <div className="stat-label">Machines</div>
          <div className="stat-value">{statistics.totalMachines}</div>
          <div className="stat-subvalue">{statistics.onlineMachines} online</div>
          <div className="stat-separator"></div>
          <div className="stat-label">Total Income</div>
          <div className="stat-value">{(statistics.totalIncome / 100).toFixed(2).replace('.', ',')} €</div>
        </div>
        <div className="stat-block">
          <div className="stat-label">Machines per Province</div>
          <div className="stat-value-small">
            {statistics.provinceStats.map(([province, stats]) => (
              <div key={province} className="province-stat">
                {province}: {stats.total} <span className="online-count">({stats.online})</span>
              </div>
            ))}
          </div>
        </div>
        <div className="stat-block">
          <div className="stat-label">Income per Province</div>
          <div className="stat-value-small">
            {statistics.provinceStats.map(([province, stats]) => (
              <div key={province} className="province-stat">
                {province}: {(stats.income / 100).toFixed(2).replace('.', ',')} €
              </div>
            ))}
          </div>
        </div>
        <div className="stat-block">
          <div className="stat-label">Coming Soon</div>
          <div className="stat-value">-</div>
          <div className="stat-subvalue">More stats</div>
        </div>
      </div>
    </div>
  );
};

export default MachineManager;
