import React, { useContext, useState, useMemo } from 'react';
import { MachineContext } from '../DataContexts/MachineContext';
import { PlaylistDataContext } from '../DataContexts/PlaylistDataContext';
import { DataContext } from '../DataContexts/DataContext';
import './MachineDetails.css';

const MachineDetails = () => {
  const { machines, loading, error, toggleMachinePlaylist,fetchCreditRequests } = useContext(MachineContext);
  const { playlists } = useContext(PlaylistDataContext);
  const { artists } = useContext(DataContext);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [playlistSearchTerm, setPlaylistSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [historyDays, setHistoryDays] = useState(90);

  const calculateRevenue = (machine) => {
    if (machine.currentCoinDeposit === undefined || machine.currentIncomeFromPayconiq === undefined) return 'N/A';
    return ((machine.currentCoinDeposit + machine.currentIncomeFromPayconiq) / 100).toFixed(2).replace('.', ',') + ' €';
  };

  const calculateDaysSinceClear = (coinsLastEmptied) => {
    if (!coinsLastEmptied || coinsLastEmptied.length < 3) return 'N/A';
    const lastClearDate = new Date(coinsLastEmptied[0], coinsLastEmptied[1] - 1, coinsLastEmptied[2]);
    const today = new Date();
    const diffTime = Math.abs(today - lastClearDate);
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  const getGoogleMapsUrl = (machine) => {
    if (!machine) return '#';
    const address = `${machine.street} ${machine.housenumber}, ${machine.postalcode} ${machine.city}, ${machine.province}`;
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const filteredMachines = useMemo(() => {
    let sorted = machines.filter((machine) =>
      machine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key) {
      sorted.sort((a, b) => {
        let aValue, bValue;
        
        switch (sortConfig.key) {
          case 'id':
            aValue = a.id;
            bValue = b.id;
            break;
          case 'name':
            aValue = a.name;
            bValue = b.name;
            break;
          case 'revenue':
            aValue = (a.currentCoinDeposit || 0) + (a.currentIncomeFromPayconiq || 0);
            bValue = (b.currentCoinDeposit || 0) + (b.currentIncomeFromPayconiq || 0);
            break;
          case 'daysSinceClear':
            aValue = calculateDaysSinceClear(a.coinsLastEmptied);
            bValue = calculateDaysSinceClear(b.coinsLastEmptied);
            if (aValue === 'N/A') aValue = -1;
            if (bValue === 'N/A') bValue = -1;
            break;
          default:
            return 0;
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return sortConfig.direction === 'asc' ? 
          String(aValue).localeCompare(String(bValue)) : 
          String(bValue).localeCompare(String(aValue));
      });
    }

    return sorted;
  }, [machines, searchTerm, sortConfig]);

  const handlePlaylistToggle = async (playlistId) => {
    if (!selectedMachine) return;
    
    const isSubscribing = !selectedMachine.playlists.includes(playlistId);
    
    try {
      await toggleMachinePlaylist(selectedMachine.id, playlistId, isSubscribing);
      setSelectedMachine(prev => ({
        ...prev,
        playlists: isSubscribing 
          ? [...prev.playlists, playlistId]
          : prev.playlists.filter(id => id !== playlistId)
      }));
    } catch (error) {
      console.error('Failed to toggle playlist:', error);
    }
  };

  const handleDaysChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setHistoryDays(value);
    }
  };

  const handleDownloadHistory = async () => {
    if (!selectedMachine) return;

    try {
      const creditRequests = await fetchCreditRequests(selectedMachine.id, parseInt(historyDays));
      
      // Function to find track details from trackId
      const findTrackDetails = (trackId) => {
        for (const artist of artists) {
          for (const album of artist.albums) {
            const track = album.tracks.find(t => t.id === trackId);
            if (track) {
              return {
                title: track.title,
                artistName: artist.name,
                genre: track.genre || 'Unknown'
              };
            }
          }
        }
        return {
          title: 'Unknown Track',
          artistName: 'Unknown Artist',
          genre: 'Unknown'
        };
      };

      // Format date from the requestedAt array
      const formatDate = (dateArray) => {
        const [year, month, day, hour, minute] = dateArray;
        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      };

      // Create CSV data
      const headers = ['Title', 'Artist Name', 'Genre', 'Date of Purchase'];
      
      const csvData = creditRequests.map(request => {
        const trackDetails = findTrackDetails(request.trackId);
        return [
          trackDetails.title,
          trackDetails.artistName,
          trackDetails.genre,
          formatDate(request.requestedAt)
        ];
      });

      // Combine headers and data, properly escape fields
      const csvContent = [
        headers.join(','),
        ...csvData.map(row => 
          row.map(field => 
            `"${String(field).replace(/"/g, '""')}"` // Escape quotes in fields
          ).join(',')
        )
      ].join('\n');

      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `machine-${selectedMachine.id}-history.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Failed to fetch or process credit requests:', error);
    }
  };

  return (
    <div className="machine-details-container">
      <div className="left-panel">
        {loading ? (
          <div className="loading-state">
            <p>Loading machines...</p>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search machines"
              className="details-search-box"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="details-table-container">
              <table className="details-table">
                <thead>
                  <tr>
                    <th onClick={() => requestSort('id')}>
                      ID{getSortIndicator('id')}
                    </th>
                    <th onClick={() => requestSort('name')}>
                      Name{getSortIndicator('name')}
                    </th>
                    <th onClick={() => requestSort('revenue')}>
                      Income (€){getSortIndicator('revenue')}
                    </th>
                    <th onClick={() => requestSort('daysSinceClear')}>
                      Income days{getSortIndicator('daysSinceClear')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMachines.map((machine) => (
                    <tr 
                      key={machine.id}
                      onClick={() => setSelectedMachine(machine)}
                      className={selectedMachine?.id === machine.id ? 'selected' : ''}
                    >
                      <td>{machine.id.slice(0, 8)}</td>
                      <td>{machine.name}</td>
                      <td className="center-data">{calculateRevenue(machine)}</td>
                      <td className="center-data">{calculateDaysSinceClear(machine.coinsLastEmptied)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <div className="right-panel">
        {loading ? (
          <div className="loading-state">
            <p>Loading details...</p>
          </div>
        ) : selectedMachine ? (
          <div className="details-grid">
            <div className="details-block info-block">
              <h2 className="details-title">{selectedMachine.name}</h2>
              <div className="details-content">
                <p><strong>ID:</strong> {selectedMachine.id}</p>
                <p><strong>Type:</strong> {selectedMachine.type}</p>
                <div className="details-spacer"></div>
                <p>{selectedMachine.street} {selectedMachine.housenumber}</p>
                <p>{selectedMachine.postalcode} {selectedMachine.city}</p>
                <p>{selectedMachine.province}</p>
                <a 
                  href={getGoogleMapsUrl(selectedMachine)} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="maps-link"
                >
                  Show in Maps
                </a>
              </div>
            </div>
            <div className="details-block stats-block">
              <h2 className="details-title">Current Stats</h2>
              <div className="details-content">
                <p><strong>Current Credits:</strong> {selectedMachine.currentCredits || 0}</p>
                <p><strong>Songs Requested:</strong> {selectedMachine.songsRequestedSince || 0}</p>
                <div className="details-spacer"></div>
                <p><strong>Income Days:</strong> {calculateDaysSinceClear(selectedMachine.coinsLastEmptied)}</p>
                <p><strong>Cash:</strong> {((selectedMachine.currentCoinDeposit || 0) / 100).toFixed(2).replace('.', ',')} €</p>
                <p><strong>Mobile Pay:</strong> {((selectedMachine.currentIncomeFromPayconiq || 0) / 100).toFixed(2).replace('.', ',')} €</p>
                <p><strong>Total Income:</strong> {calculateRevenue(selectedMachine)}</p>
              </div>
            </div>
            <div className="details-block playlists-block">
              <h2 className="details-title">Playlists</h2>
              <div className="details-content">
                <div className="playlist-search">
                  <input
                    type="text"
                    placeholder="Search Playlists"
                    value={playlistSearchTerm}
                    onChange={(e) => setPlaylistSearchTerm(e.target.value)}
                    className="details-search-box"
                  />
                  <div className="playlist-count">
                    {selectedMachine?.playlists?.length || 0}/{playlists.length} Subscribed
                  </div>
                </div>
                <div className="playlist-list">
                  {playlists
                    .filter(playlist => 
                      playlist.name.toLowerCase().includes(playlistSearchTerm.toLowerCase()) ||
                      playlist.id.toLowerCase().includes(playlistSearchTerm.toLowerCase())
                    )
                    .map(playlist => (
                      <div key={playlist.id} className="playlist-item">
                        <input
                          type="checkbox"
                          checked={selectedMachine?.playlists?.includes(playlist.id)}
                          onChange={() => handlePlaylistToggle(playlist.id)}
                          id={`playlist-${playlist.id}`}
                        />
                        <label htmlFor={`playlist-${playlist.id}`}>
                          {playlist.name} ({playlist.playlistType})
                        </label>
                      </div>
                    ))}
                </div>
                <div className="history-download-section">
                  <div className="history-input-group">
                    <input
                      type="number"
                      min="1"
                      value={historyDays}
                      onChange={handleDaysChange}
                      className="history-days-input"
                    />
                    <span className="history-days-label">past days</span>
                    <button 
                      className="download-history-btn"
                      onClick={handleDownloadHistory}
                      disabled={!selectedMachine}
                    >
                      Download History
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-selection">
            <p>Select a machine to view details</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MachineDetails;
