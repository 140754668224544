import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Notification from '../components/Notification';

export const MachineContext = createContext({
  machines: [],
  loading: false,
  error: null,
  fetchMachines: async () => {},
  toggleMachinePlaylist: async () => {},
  fetchCreditRequests: async () => {},
});

const MachineProvider = ({ children }) => {
  const { authData } = useContext(AuthContext);
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const showSuccessNotification = (message) => {
    addNotification(message, 'success');
  };

  const showErrorNotification = (message) => {
    addNotification(message, 'error');
  };

  const fetchMachines = async () => {
    if (!authData?.accessToken) {
      console.warn("No access token found, skipping fetch.");
      return;
    }
    
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching machines...");
      const response = await fetch(
        'https://backend.digitalejukebox.be/machines/?page=0&size=200',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch machines');
      }

      let machinesData = await response.json();
      
      // Add this new function to fetch playlists
      const fetchPlaylists = async (machine) => {
        try {
          const playlistResponse = await fetch(
            `https://backend.digitalejukebox.be/machines/${machine.id}/playlists/`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          
          if (!playlistResponse.ok) {
            throw new Error('Failed to fetch playlists data');
          }
          
          const playlistData = await playlistResponse.json();
          return { ...machine, playlists: playlistData };
        } catch (error) {
          console.error(`Failed to fetch playlists for machine ${machine.id}:`, error);
          return { ...machine, playlists: [] }; // Return empty playlists array on error
        }
      };

      // Modify the existing fetchAdministration to include playlists
      const fetchAdministration = async (machine) => {
        try {
          const adminResponse = await fetch(
            `https://backend.digitalejukebox.be/machines/${machine.id}/administration`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          
          if (!adminResponse.ok) {
            throw new Error('Failed to fetch administration data');
          }
          
          const adminData = await adminResponse.json();
          delete adminData.id;

          // Fetch playlists after administration data
          const machineWithAdmin = { ...machine, ...adminData };
          return await fetchPlaylists(machineWithAdmin);
        } catch (error) {
          console.error(`Failed to fetch administration for machine ${machine.id}:`, error);
          // Still try to fetch playlists even if admin data fails
          return await fetchPlaylists(machine);
        }
      };
      
      const concurrencyLimit = 10;
      const fetchWithConcurrency = async (machines) => {
        const results = [];
        for (let i = 0; i < machines.length; i += concurrencyLimit) {
          const batch = machines.slice(i, i + concurrencyLimit);
          const fetchedBatch = await Promise.all(batch.map(fetchAdministration));
          results.push(...fetchedBatch);
        }
        return results;
      };
      
      machinesData = await fetchWithConcurrency(machinesData);
      setMachines(machinesData);
    } catch (error) {
      setError(error.message);
      showErrorNotification(`Error fetching machines: ${error.message}`);
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleMachinePlaylist = async (machineId, playlistId, isSubscribing) => {
    if (!authData?.accessToken) {
      console.warn("No access token found, skipping update.");
      return;
    }

    try {
      const response = await fetch(
        `https://backend.digitalejukebox.be/machines/${machineId}/playlists/${playlistId}`,
        {
          method: isSubscribing ? 'POST' : 'DELETE',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to ${isSubscribing ? 'subscribe to' : 'unsubscribe from'} playlist`);
      }

      // Update the local state
      let updatedMachine = null;
      setMachines(prevMachines => {
        const newMachines = prevMachines.map(machine => {
          if (machine.id === machineId) {
            const newPlaylists = isSubscribing
              ? [...machine.playlists, playlistId]
              : machine.playlists.filter(id => id !== playlistId);
            updatedMachine = { ...machine, playlists: newPlaylists };
            return updatedMachine;
          }
          return machine;
        });
        return newMachines;
      });

      showSuccessNotification(
        `Successfully ${isSubscribing ? 'subscribed to' : 'unsubscribed from'} playlist`
      );

      // Return the updated machine
      return updatedMachine;
    } catch (error) {
      showErrorNotification(error.message);
      throw error;
    }
  };

  const fetchCreditRequests = async (machineId, numberOfDays) => {
    if (!authData?.accessToken) {
      console.warn("No access token found, skipping fetch.");
      return [];
    }

    try {
      // Calculate the from date
      const today = new Date();
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - numberOfDays);
      
      // Format date to yyyy-MM-dd
      const formattedDate = fromDate.toISOString().split('T')[0];

      const response = await fetch(
        `https://backend.digitalejukebox.be/machines/${machineId}/credit-requests?from=${formattedDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch credit requests');
      }

      const creditRequests = await response.json();
      return creditRequests;

    } catch (error) {
      showErrorNotification(`Failed to fetch credit requests: ${error.message}`);
      throw error;
    }
  };

  useEffect(() => {
    fetchMachines();
  }, [authData?.accessToken]);

  return (
    <MachineContext.Provider 
      value={{ 
        machines, 
        loading, 
        error, 
        fetchMachines,
        toggleMachinePlaylist,
        fetchCreditRequests
      }}
    >
      {children}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => setNotifications((prev) => 
              prev.filter((n) => n.id !== notification.id)
            )}
          />
        ))}
      </div>
    </MachineContext.Provider>
  );
};

export default MachineProvider;