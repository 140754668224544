import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import MusicManager from './MusicManager/MusicManager';
import MachineManager from './MachineManager/MachineManager';
import AdsManager from './AdsManager/AdsManager';
import TagManager from './TagManager/TagManager';
import PlaylistManager from './PlaylistManager/PlaylistManager';
import PlaytreeManager from './PlaytreeManager/PlaytreeManager';
import TrackUpload from './TrackUpload/TrackUpload';
import Login from './Login';
import AuthProvider, { AuthContext } from './AuthContext'; // Import both AuthProvider and AuthContext
import DataProvider from './DataContexts/DataContext';
import PlaylistDataProvider from './DataContexts/PlaylistDataContext';
import PlaytreeDataProvider from './DataContexts/PlaytreeDataContext';
import UploadProvider from './DataContexts/UploadContext'; // Import UploadProvider
import './App.css';
import PlaylistHeader from './PlaylistManager/PlaylistHeader';
import MachineProvider from './DataContexts/MachineContext';
import MachineHeader from './MachineManager/MachineHeader';
const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
};

const AppRoutes = () => {
  const { authData, authLoading } = useContext(AuthContext); // Use AuthContext here

  if (authLoading) {
    return <p>Loading...</p>;
  }

  if (!authData) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <DataProvider>
      <PlaylistDataProvider>
        <PlaytreeDataProvider>
          <UploadProvider>
            <MachineProvider>
            <Header />
            <div className="content">
              <Routes>
                <Route path="/" element={<MusicManager />} />
                <Route path="/trackupload" element={<TrackUpload />} />
                <Route path="/tagmanager" element={<TagManager />} />
                <Route path="/playlists" element={<PlaylistHeader />} />
                <Route path="/playlistmanager" element={<PlaylistManager />} />
                <Route path="/playtreemanager" element={<PlaytreeManager />} />
                <Route path="/machines" element={<MachineHeader />} />
                <Route path="/machine-manager" element={<MachineManager />} />
                <Route path="/ads-manager" element={<AdsManager />} />
                <Route path="/login" element={<Navigate to="/" />} />
              </Routes>
            </div>
            </MachineProvider>
          </UploadProvider>
        </PlaytreeDataProvider>
      </PlaylistDataProvider>
    </DataProvider>
  );
};

export default App;
